<template>
  <div class="sidebar-wrapper">
    <el-menu
      :default-active="active"
      class="sidebar-menu"
      :collapse-transition="false"
      :collapse="ifCollapse"
      @select="handleSelectMenuItem"
    >
      <el-menu-item
        v-for="menuItem in menuItemList"
        :key="menuItem.name"
        :index="menuItem.name"
        class="sidebar-menu-item"
      >
        <i :class="['iconfont', menuItem.icon, 'icon']"></i>
        <template #title>
          {{ menuItem.label }}
        </template>
      </el-menu-item>
      <!-- <el-button
        v-show="!ifCollapse"
        link
        class="collapse-btn"
        @click="handleClickCollapse"
      >
        <i class="iconfont icon-a-129-shouqi_zhankai"></i>
      </el-button> -->
      <el-button link class="collapse-btn" @click="handleClickCollapse">
        <i
          :class="[
            'iconfont',
            ifCollapse
              ? 'icon-a-128-zhankai_shouqi'
              : 'icon-a-129-shouqi_zhankai',
          ]"
        ></i>
      </el-button>
    </el-menu>
  </div>
</template>

<script setup>
import { reactive, ref, defineEmits, defineProps } from "vue";
import { MODULE } from "@/constant/router/module-name";
defineProps({
  active: String,
});

const emits = defineEmits(["onCollapse", "select"]);

//菜单
const menuItemList = ref([
  {
    label: "首页",
    name: MODULE.HOME,
    icon: "icon-shouye",
  },
  {
    label: "项目",
    name: MODULE.PROJECT,
    icon: "icon-wodexiangmu",
  },
  {
    label: "任务记录",
    name: MODULE.TASK_RECORD,
    icon: "icon-renwu",
  },
  {
    label: "策略中心",
    name: MODULE.SCHEDULE_CENTER,
    icon: "icon-jihuarenwu",
  },
  {
    label: "爬虫模板",
    name: MODULE.SPIDER_TEMPLATE,
    icon: "icon-DA",
  },
  {
    label: "回收站",
    name: MODULE.RECYCLE_BIN,
    icon: "icon-shanchu",
  },
]);
const handleSelectMenuItem = (index) => {
  emits("select", index);
};

const ifCollapse = ref(false);
//点击展开/收缩
const handleClickCollapse = () => {
  ifCollapse.value = !ifCollapse.value;
  emits("on-collapse", ifCollapse.value);
};
</script>

<style lang="scss" scoped>
:deep(.el-menu) {
  border: none;
  box-shadow: 0px 0px 5px 0px rgba(206, 206, 206, 0.4);
}
.sidebar-wrapper {
  height: 100%;
  .sidebar-menu {
    height: 100%;
    padding-top: 2px;
    .el-menu-item {
      --el-menu-base-level-padding: 11px;
      height: 36px;
      line-height: 36px;
      border-radius: 8px;
      margin: 24px 12px;
      color: #606266;
    }
    .el-menu-item:first-child {
      margin-top: 20px;
    }
    .el-menu-item.is-active {
      background: #f7f4ff;
      color: $theme-color;
    }
    .el-menu-item:focus,
    .el-menu-item:hover {
      background: #f7f4ff;
      color: $theme-color;
    }
    .collapse-btn {
      position: absolute;
      bottom: 10px;
      left: 10px;
    }
  }
  .iconfont {
    margin-right: 16px;
  }
}
</style>
