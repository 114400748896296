const SPIDER_STATUS = {
  DRAFT: 1, //草稿
  ENABLED: 2, //启用中
  DISABLED: 3, //禁用中
  // COMPLETED: 3, //已完成
  // NOTTESTED: 4, //未测试
  // TESTED: 5, //已测试
};
const SPIDER_STATUS_MAP = {
  [SPIDER_STATUS.DRAFT]: {
    label: "草稿",
    type: "info",
  },
  [SPIDER_STATUS.ENABLED]: {
    label: "启用中",
    type: "warning",
  },
  [SPIDER_STATUS.DISABLED]: {
    label: "禁用中",
    type: "danger",
  },
  // [SPIDER_STATUS.COMPLETED]: {
  //   label: "已完成",
  //   type: "success",
  // },
  // [SPIDER_STATUS.NOTTESTED]: {
  //   label: "未测试",
  //   type: "info",
  // },
  // [SPIDER_STATUS.TESTED]: {
  //   label: "已测试",
  //   type: "",
  // },
};
const SPIDER_STATUS_ACTION = {
  DISABLE: "禁用",
  ENABLE: "启用",
  TEST: "测试",
};
const SPIDER_STATUS_ACTION_MAP = {
  [SPIDER_STATUS.ENABLED]: {
    action: SPIDER_STATUS_ACTION.DISABLE,
  },
  [SPIDER_STATUS.DISABLED]: {
    action: SPIDER_STATUS_ACTION.ENABLE,
  },
  // [SPIDER_STATUS.COMPLETED]: "",
  [SPIDER_STATUS.NOTTESTED]: {
    action: SPIDER_STATUS_ACTION.TEST,
  },
  [SPIDER_STATUS.TESTED]: {
    action: SPIDER_STATUS_ACTION.DISABLE,
  },
};
const getStatusAction = (status) => {
  return SPIDER_STATUS_ACTION_MAP[status].action;
};
const SPIDER_STATUS_OPTION = Object.keys(SPIDER_STATUS_MAP).map((key) => ({
  label: SPIDER_STATUS_MAP[key].label,
  value: key,
}));
export {
  SPIDER_STATUS_MAP,
  SPIDER_STATUS_OPTION,
  SPIDER_STATUS,
  SPIDER_STATUS_ACTION_MAP,
  SPIDER_STATUS_ACTION,
  getStatusAction,
};
