<template>
  <el-tag :type="type" :color="bgColor" :style="style" :key="type">
    {{ title }}
  </el-tag>
</template>

<script setup>
import { ref, defineProps, computed } from "vue";
import { SPIDER_STATUS_MAP } from "@/constant/spider_status.js";
const props = defineProps({
  status: {
    type: Number,
  },
});
console.log(props.status);
const type = computed(() => {
  return SPIDER_STATUS_MAP[props.status]?.type || "";
});
const title = computed(() => {
  return SPIDER_STATUS_MAP[props.status]?.label || "";
});
const style = computed(() => {
  const type = SPIDER_STATUS_MAP[props.status]?.type;
  if (type) {
    return null;
  } else {
    return `color: #1A78FF;border-color:#3894FF26`;
  }
});
const bgColor = computed(() => {
  const type = SPIDER_STATUS_MAP[props.status]?.type;
  if (type) {
    return null;
  } else {
    return `#3894FF26`;
  }
});
</script>

<style lang="scss" scoped></style>
